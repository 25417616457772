@import "./variables";

.UserProfile {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-heading;
    font-size: $font-size-tiny;
    cursor: pointer;
    text-decoration: none;

    // avoid hover style on touch screen
    @media (hover: "hover") {
        &:hover {
            background-color: $color-background-100;
        }
    }

    &.selected {
        background-color: $color-background-100;
    }

    &-user,
    &-close {
        margin-right: $unit;
        color: $color-primary-900;
        text-decoration: none;
        width: $unit * 12;
        text-align: right;
    }

    &-user {
        border-bottom: $unit / 4 solid $color-primary-700;
        color: $color-primary-700;
    }

    button {
        background-color: transparent;
        width: $unit * 4;
        height: 100%;
        padding: $unit;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
