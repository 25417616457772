@import "./variables";

.me-zerobooks {
    text-align: center;
    &__container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: $color-background-100;
    }

    h1 {
        font-size: $font-size-large;
        font-family: $font-family-body;
        color: lighten($color-primary-900, 25%);
    }

    p {
        font-size: $font-size-large * 0.75;
        color: $color-primary-900;
    }

    a {
        display: inline-block;
        border: 1px solid $color-accent;
        color: $color-accent;
        background-color: transparent;
        margin-top: $unit * 5;
        padding: $unit;
        text-decoration: none;
        font-family: $font-family-button;
        font-size: $font-size-large;
    }
}
