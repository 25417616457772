@import "./variables";

.me-document-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__container {
        background-color: $color-background-primary;
        overflow: hidden;
        width: $unit * 20;
        max-height: $unit * 30;
    }

    &__image {
        height: 80%;
        border-radius: $unit / 2;
        &-container {
            display: flex;
            justify-content: center;
            align-items: center;

            height: $unit * 12;
            width: 100%;
            overflow: hidden;
            background: radial-gradient(
                    142.02px at 50% 50%,
                    #ffffff 0%,
                    rgba(255, 255, 255, 0) 100%
                ),
                linear-gradient(0deg, #d5d6db, #d5d6db);
        }
    }

    &__details {
        padding: $unit;
        color: $color-primary-900;
        margin-bottom: $unit;

        label.languages {
            display: block;
            text-transform: uppercase;
            font-family: $font-family-heading;
            font-size: $font-size-tiny;
            margin-bottom: $unit;

            span:first-child {
                &:after {
                    content: " & ";
                }
            }
        }

        & > div:last-child {
            width: 100%;

            label {
                overflow: hidden;
                width: 100%;
                font-size: $font-size-small;
                font-family: $font-family-heading;
            }
        }
    }

    &__action {
        flex-grow: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        font-size: $font-size-default;
        font-family: $font-family-heading;

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 $unit;
            height: $unit * 4;
            font-size: $font-size-tiny;
            text-transform: capitalize;

            & > label {
                color: darken($color-primary, 20%);
            }

            &.me-document-item__action-status {
                justify-content: center;
                text-transform: uppercase;

                &.published {
                    color: $color-accent;
                }
            }
        }
    }
}

.me-cover__placeholder {
    max-width: 100px;
    max-height: 152px;
    width: 50%;
    height: 65%;
    border: 1px dashed $color-primary-900;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-heading;
    font-size: $font-size-default;
}
