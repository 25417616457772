@import "variables";

.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $unit * 3;
    border-top: 1px solid $color-background-100;
    border-bottom: 1px solid $color-divider;
    width: 100%;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            width: $unit * 6;
            height: 100%;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-small;
            font-family: $font-family-heading;
            cursor: pointer;
            transition: background-color 0.3s;
            text-decoration: none;
            width: 100%;
            height: 100%;
            min-height: $unit * 3;
            color: $color-primary-900;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 0;
                left: 0;
                bottom: 0;
                background-color: $color-accent;
                opacity: 0;
                transition: opacity 0.25s, height 0.25s;
            }

            &:hover {
                position: relative;
                &::before {
                    opacity: 1;
                    height: $unit / 4;
                }
            }

            &.selected {
                position: relative;
                &::before {
                    opacity: 1;
                    height: $unit / 4;
                }
            }
        }
    }

    &__mid {
        flex-grow: 1;
        text-align: left;
        height: 100%;
        border-left: 1px solid $color-divider;

        a {
            height: 100%;
        }
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $unit * 6;
        text-align: center;
        text-decoration: none;
        height: 100%;
        color: $color-primary-900;
        font-family: $font-family-button;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            bottom: 0;
            background-color: $color-accent;
            opacity: 0;
            transition: opacity 0.25s, height 0.25s;
        }

        &:hover {
            position: relative;
            &::before {
                opacity: 1;
                height: $unit / 4;
            }
        }
    }
}
