@import "_variables";
// Ensure the right caps are used
.me-document-edit {
    height: 100%;
    background-color: $color-background-200;
    &__notice {
        background-color: red;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: $spacing-default / 2;
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        font-family: $font-family-heading;

        &::after {
            content: "This older document needs to be converted to a newer format! Until then adding and removing paragraphs is disabled. Click here to send an email to the admin to convert the book. An email notification will be sent when conversion is complete (1 / 2 days).";
            position: absolute;
            width: 50%;
            height: 400%;
            top: 300%;
            padding: $spacing-default;
            background-color: $color-primary-900;
            color: $color-primary;
            z-index: 1000;
            text-transform: initial;
            opacity: 0;
            transition: opacity 0.5s;
            font-size: 1em;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    &__languages {
        width: 100%;
        height: $unit * 2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid $color-primary-800;
        font-family: $font-family-heading;
        font-size: $font-size-tiny;

        background-color: $color-background-200;
        color: $color-text-primary;
    }
}

.me-paragraph {
    width: 100%;
    flex: 2 0 0;
    font-size: $font-size-default;
    font-family: $font-family-heading;
    color: $color-text-primary;

    position: relative;
    padding: 0;
    margin: 0;

    &__row {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        min-height: $unit * 10;
        border-bottom: 1px solid $color-primary-800;
        &-container {
            overflow-y: scroll;
            height: calc(100vh - #{$unit * 8});
            width: 100%;
            -webkit-overflow-scrolling: touch;
        }
    }

    // The paragraph use to calculate size
    &__view {
        position: initial;
        font-size: $font-size-medium;
    }

    // The text area
    &__editing {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: "Quicksand", "Roboto", "Helvetica", "sans-serif";
        font-weight: 700;
        font-size: $font-size-medium;
        resize: none;
        padding: $spacing-default;
        border-width: 0;
        transition: border-color 0.5s;
        outline: none;
        border-radius: 0;
        appearance: none; // needed to remove inner-shadow on iOS
        margin: 0;
        background-color: $color-background-200;
        color: $color-primary-900;

        &:active,
        &:focus {
            border-bottom-color: $color-accent;
            background-color: $color-background-primary;
        }

        &::placeholder {
            color: transparentize($color: $color-primary-900, $amount: 0.9);
        }
    }

    &__divider {
        width: 1px;
        background-color: $color-primary-800;
    }

    &__action {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-width: 3 * $unit;
        width: 3 * $unit;
        font-family: $font-family-heading;

        &-number {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50%;
        }

        &-button {
            color: $color-text-primary;
            font-size: $font-size-default;
            background-color: transparent;
            height: 50%;

            &:hover {
                color: $color-accent;
            }
        }
    }
}
