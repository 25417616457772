@import "variables";

.ChapterList {
    color: $color-primary-900;
    background-color: $color-background-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $color-divider;
    width: 100%;
    margin: 0 auto;

    &__chapters {
        display: grid;
        grid-template-columns: repeat(3, $unit * 10);
        grid-auto-flow: row;
        grid-gap: $unit;
        justify-items: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100vh - #{$unit * 9});
        overflow-y: scroll;

        .selected-chapter {
            color: $color-primary-900;
            font-weight: 600;
            position: relative;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: $unit / 4;
                background-color: $color-accent;
            }

            &::after {
                bottom: 0;
            }
        }
    }
    &__item {
        color: $color-primary-900;
        background-color: $color-background-primary;
        cursor: pointer;
        font-weight: 400;
        font-family: $font-family-heading;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.25s;
        border-width: 0;
        width: 100%;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            background-color: $color-accent;
            transition: height 0.25s;
        }
        &::after {
            bottom: 0;
        }

        // fixes smal bug where a small residu of the green line
        // remains visible
        &:not(.selected-chapter) {
            &::before {
                opacity: 0;
            }
        }

        @media (hover: "hover") {
            &:hover {
                &::before,
                &::after {
                    height: $unit / 4;
                    opacity: 1;
                }
            }
        }

        .ChapterItem-content {
            width: 100%;
            height: $unit * 10;
            min-height: $unit * 3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-large;
        }

        .ChapterItem-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            & > div {
                font-size: $font-size-small;
                padding-left: $unit;
                color: darken($color-primary, 10%);
            }

            button .material-icons {
                color: darken($color-primary, 10%);
            }
        }

        button {
            background-color: transparent;
            text-transform: uppercase;
            font-size: 0.75em;
            height: 100%;
            margin: 0;
            transition: background-color 0.25s;

            @media (hover: "hover") {
                &:hover {
                    background-color: $color-primary-900;
                    color: $color-primary;
                }
            }
        }
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid orange;
        width: 100%;
        padding: $unit 0;
        color: $color-primary-900;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-family-heading;
        }

        button {
            color: $color-primary;
            background-color: $color-accent;
            width: 10 * $unit;
            height: 100%;
            padding: $unit / 2;
            margin-left: $unit;
            border-radius: $unit / 4;

            &:hover {
                color: $color-primary;
            }
        }
    }
}
