@import "./variables";
.Login_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    min-width: 300px;
    font-size: $font-size-default;

    input {
        border-radius: 0;
        padding: 0;
        background-color: transparent;
    }
}

label.Login_label {
    padding: var(--spacing-default) 0;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    width: 80%;

    div {
        position: relative;
        font-size: 0.6em;

        &:after {
            content: "required";
            position: absolute;
            right: 0;
            color: transparentize($color-primary-900, 0.25);
        }
    }
}

/* Login_form_action_container */

.Login_form_action_container {
    margin: calc(2 * var(--spacing-default)) auto 0;
    width: 80%;
    margin-bottom: var(--spacing-default);
    overflow: hidden; // hide the buttton overflow underneath
}

.Login_primary_action {
    width: 100%;
    outline: 1px solid var(--color-secondary);
    color: var(--color-primary);
    font-weight: 600;
}

.Login_form_action_other {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: flex-end;
    min-height: var(--spacing-height);

    a {
        padding: $spacing-default / 2;
        color: transparentize($color-primary-900, 0.25);
        font-family: $font-family-heading;
        cursor: pointer;
    }
}
