@import "./variables";

.me-stats-list {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: $spacing-default * 4 0;
        color: #33363c;

        display: flex;
        & > div {
            margin-left: $spacing-default;

            &:first-child {
                margin: 0;
            }
        }
    }
}
