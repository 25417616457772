@import "./variables";

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: $color-background-100;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto";
    font-weight: 400;
}

button {
    padding: var(--spacing-default);
    font-size: var(--font-size-default);
    background-color: var(--color-accent);
    color: var(--color-primary-900);
    border: none;
    cursor: pointer;
}

input {
    border: none;
    border-bottom: 1px solid var(--color-divider);
    font-size: var(--font-size-default);
    font-family: "Roboto", sans-serif;
    margin-top: var(--spacing-default);
    width: 100%;
    min-height: var(--spacing-height);
    border-radius: 0;
}

input:active,
input:focus {
    outline: none;
    border-bottom: 1px solid var(--color-accent);
}

/**
* The main app container. Everything should be placed inside of this.
*/
.me-app__container {
    height: 100vh;
    width: 100vw;

    .Main__container {
        height: calc(100vh - #{$unit * 6});
        background-color: $color-background-100;
    }
}
