@import "variables";

.Review {
    max-width: 64 * $unit;
    margin: $unit * 2 auto;
    // background-color: $color-background-primary;

    &-container {
        height: calc(100vh - #{$unit * 6});
        overflow-y: scroll;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: $unit;

        &-titles {
            width: 100%;
            padding-right: $unit;
        }
        &-title {
            color: $color-text-primary;
            font-size: $font-size-large * 1.5;
            margin-bottom: $unit * 2;
            font-family: $font-family-heading;

            label {
                display: block;
                color: $color-primary-700;
                font-size: $font-size-small;
            }
        }

        &-cover {
            flex: 1 0 1;
            & > div {
                display: flex;
                flex-direction: column;

                img {
                    height: 250px;
                    width: 175px;
                }

                button {
                    background-color: transparent;
                }
            }
        }

        &-upload {
            flex: 1 0 1;
            height: 100%;

            .upload-container {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                // required to avoid resizing;
                min-height: 306px;
                min-width: 175px;

                button {
                    width: 175px;
                    height: 250px;
                    background-color: $color-primary;
                }

                & [type="file"] {
                    font-size: 200px;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &__stats-list {
        display: flex;

        .me-stat {
            font-family: $font-family-heading;
            text-align: center;
            border: 1px solid$color-background-100;
            border-right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 10 * $unit;
            width: 100%;
            color: $color-primary-700;

            &:first-child {
                border-left: 0;
            }

            & > div {
                font-size: $font-size-large;
                font-weight: 800;
                color: $color-text-primary;
            }

            & > label {
                text-transform: capitalize;
            }
        }
    }

    &__descriptions {
        margin-top: $unit;
        padding-bottom: $unit;

        & > label {
            font-family: $font-family-heading;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $unit;
            justify-content: space-between;
            align-items: center;
            height: $unit * 3;
            padding-left: $unit;
            padding-right: $unit;

            & > span {
                width: 50%;
                text-align: left;
                color: $color-primary-700;
            }
        }

        & > div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $unit;
            padding-left: $unit;
            padding-right: $unit;
        }
    }

    &__price {
        display: flex;
        height: 10 * $unit;
        padding: $unit;
        &-container {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-top: $unit * 2;

            & > * {
                flex: 1;
            }

            button {
                color: $color-background-primary;
                font-size: $font-size-large;
                text-transform: capitalize;

                &.isPublished {
                    background-color: $color-primary-700;
                }
            }
        }
        span {
            font-family: $font-family-heading;
            width: 30%;
            color: $color-primary-700;
        }

        & > div {
            input {
                display: inline-block;
                text-align: right;
                font-size: $font-size-large * 2;
                color: $color-primary-900;
                margin: 0;
                width: 50%;
                height: 100%;
                background-color: transparent;
            }

            label {
                display: inline-block;
                width: 50%;
                text-transform: uppercase;
                font-family: $font-family-heading;
                color: $color-primary-700;
                vertical-align: top;
                padding: $unit * 2 0 0 $unit;
            }
        }
    }
}
