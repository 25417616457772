@import "variables";

.NewBook {
    min-width: 500px;
    width: 80%;
    display: flex;
    justify-content: space-between;

    &.summary {
        background-color: transparent;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-background-100;
        height: 100%;
        width: 100%;
        overflow-y: scroll;

        h1 {
            font-size: $font-size-large;
            color: lighten($color-primary-900, 25%);
        }
    }
}

// The new book checklist
.NewBook-checklist {
    display: flex;
    width: 30%;
    height: 100%;
    flex-direction: column;
    font-size: $font-size-default;
    font-family: $font-family-heading;
    // border-right: $unit * 4 solid $color-background-primary;
    counter-reset: my-counter;
    // border-right: $unit solid red;
    background-color: $color-background-primary;
    overflow-y: scroll;

    $transition-time: 0.5s;

    dl {
        margin: 0;
        border-right: $unit solid $color-background-100;
        height: 100%;
    }

    dt {
        color: $color-primary-900;
        font-size: $font-size-small;
        display: flex;
        align-items: center;
        transition: color $transition-time, background-color $transition-time;
        position: relative;
        padding-left: $unit * 4;
        height: $unit * 6;
        width: 100%;
        min-width: $unit * 4;
        border-bottom: 1px solid $color-background-100;

        &:before {
            content: counter(my-counter);
            counter-increment: my-counter;
            display: inline-flex;
            position: absolute;
            width: $unit * 2;
            height: $unit * 2;
            left: $unit;
            font-size: $font-size-small;
            border-radius: 50%;
            background-color: $color-background-100;
            justify-content: center;
            align-items: center;
            margin-right: $unit;
            transition: color $transition-time,
                background-color $transition-time;
        }

        &.step-selected {
            color: $color-accent;

            &:before {
                background-color: $color-accent;
                color: $color-primary;
            }
        }

        &.step-done {
            color: darken($color-primary, 20%);
            &:before {
                background-color: transparent;
            }
        }
    }
}

.NewBookForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 70%;
    // height: 100%;
    min-height: $unit;
    background-color: $color-background-primary;
    overflow-y: scroll;

    label {
        display: block;
        width: 100%;
        font-family: $font-family-heading;
        padding: 0 $unit;
        align-self: center;

        span {
            font-size: $font-size-tiny;
            text-transform: uppercase;
            margin-bottom: $spacing-default;
            display: inline-block;
            font-family: $font-family-heading;
            color: lighten($color-primary-900, 25%);

            span {
                font-weight: 800;
            }
        }

        select {
            font-size: $font-size-large;
            width: 100%;
            border: 0;
            border-bottom: 1px solid $color-divider;
            background-color: transparent;
            border-radius: 0;

            option {
                font-size: $font-size-default;
                border: 0;
                border-radius: 0;
            }
        }

        input {
            font-size: $font-size-large;
            color: $color-primary-900;
            width: 100%;
        }

        small {
            color: lighten($color-primary-900, 50%);
            position: relative;
            top: $unit;
        }
    }

    &__content {
        flex: 1;
        width: 100%;
        display: flex;

        input {
            padding: 0;
            margin: 0;
        }
    }

    &__action {
        text-align: center;
        display: flex;

        button {
            margin: 0;
        }

        .btn-primary {
            width: $unit * 12;
            font-size: $font-size-default;
            text-transform: capitalize;
            color: lighten($color-primary-900, 25%);
            background-color: $color-primary;
            transition: background-color 0.25s, color 0.25s;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-accent;
                color: $color-primary;
            }
        }

        .btn-back {
            display: flex;
            background-color: transparent;
            width: $unit * 12;
            justify-content: center;
            align-items: center;
            color: lighten($color-primary-900, 25%);
            font-family: $font-family-heading;
            font-size: $font-size-default;
            cursor: pointer;
            text-transform: capitalize;
            transition: background-color 0.25s, color 0.75s;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-primary;
                color: $color-primary-900;
            }
        }
    }
}

.NewBookSummary {
    width: 300px;
    height: 438px;
    background-color: $color-background-primary;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &__cover {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        background: radial-gradient(
                142.02px at 50% 50%,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(0deg, #d5d6db, #d5d6db);

        div {
            width: 100px;
            height: 152px;
            border: 1px dashed $color-primary-900;
            border-radius: $unit / 2;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family-heading;
            font-size: $font-size-default;
        }
    }

    &__content {
        height: 50%;
        padding: $unit;
        font-family: $font-family-heading;
        color: $color-primary-900;

        span {
            text-transform: uppercase;
            margin-bottom: $unit / 2;
        }

        div {
            margin-bottom: $unit / 2;
        }
    }

    &__languages {
        margin-bottom: $unit;
        display: block;
        span:first-child::after {
            content: " & ";
        }
    }

    &__action {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        button {
            font-size: $font-size-tiny;
            flex: 1 1 auto;
            color: $color-primary;
            text-transform: capitalize;
            background-color: transparent;
        }

        :first-child {
            color: $color-primary-900;
        }

        :last-child {
            color: $color-accent;
        }
    }
}
