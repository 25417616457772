@import "./variables";
.AccessPage {
    height: 100vh;
    width: 100vw;

    section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    & > * {
      flex: 1;
    }
  }
}
