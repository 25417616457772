@import "variables";

.EditableTitle {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 4 * $unit;
    font-size: $font-size-large;
    font-family: $font-family-heading;
    color: $color-primary-900;
    border: none;

    textarea,
    p {
        display: block;
        width: 100%;
        margin: 0;
        padding: 1px 0;
        border: none;
    }

    textarea {
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: none;
        height: 100%;
        font-size: $font-size-large;
        font-family: $font-family-heading;
        background-color: $color-background-100;
        opacity: 0;
        transition: opacity 0.3s;

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
            border: none;
        }
    }
}
