@import "colors";

:root {
    /* spacing */
    --spacing-default: 16px;
    --spacing-height: 44px;

    /* fonts */

    --font-size-small: 18px;
    --font-size-default: 22px;
    --font-size-large: 36px;
    --font-family-heading: "Roboto", "sans-serif";
    --font-family-body: "Georgia", "serif";
}

$unit: 16px;
$spacing-default: $unit;

// fonts
$font-size-large: 36px;
$font-size-medium: 28px;
$font-size-default: 22px;
$font-size-small: 18px;
$font-size-tiny: 14px;

$font-family-heading: "Roboto", "sans-serif";
$font-family-body: "Georgia", "serif";
$font-family-button: $font-family-heading;
