@import "variables";

.EditableParagraph {
    position: relative;
    width: 100%;
    min-height: 10 * $unit;
    font-size: $font-size-default;
    font-family: $font-family-heading;
    color: $color-primary-900;

    &:hover {
        background-color: $color-background-100;
    }

    textarea,
    p {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    textarea {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 0;
        font-size: $font-size-default;
        font-family: $font-family-heading;
        padding: 0;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.3s;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-background-100;
            opacity: 1;
        }
    }
}
