@import "./variables";

.UserProfilePage {
    background-color: $color-background-primary;
    width: 64 * $unit;
    min-height: 64 * $unit;
    margin: 0 auto;
    padding: 2 * $unit;
    font-family: $font-family-heading;
    display: flex;
    flex-direction: column;

    &-container {
        background-color: $color-background-100;
        height: calc(100vh - #{$unit * 6});
        text-align: center;
        padding: (4 * $unit) 0;
        overflow: scroll;
    }

    &__save {
        &-container {
            margin-top: $unit * 2;
            padding-top: $unit / 2;
            border-top: 1px solid $color-primary-800;
            text-align: left;

            button {
                background-color: transparent;
                text-transform: capitalize;
                text-align: left;
                padding: 0;
                margin: $unit $unit * 2 0 0;
                transition: color 0.3s;
                &:hover {
                    color: $color-accent;
                }
            }
        }
    }
}

.UserInfo {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 4 * $unit;

    &-text {
        width: calc(100% - #{$unit * 18});
        label {
            text-align: left;
            width: 100%;
            display: block;
            text-transform: capitalize;
            color: $color-text-primary;
        }

        input {
            color: $color-primary-900;
            margin-top: 0;
            margin-bottom: 2 * $unit;
            border-width: 0;
            background-color: transparent;
            border-bottom: 1px solid transparent;
        }

        .email {
            text-align: left;
            color: $color-text-primary;
            font-size: $font-size-default;
        }
    }

    .Photo {
        &-container {
            height: $unit * 16;
            width: $unit * 16;
            // background-color: $color-background-100;
            // background-color: $color-primary;
            // border: 1px solid $color-primary;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: $color-text-primary;

            img {
                margin-bottom: $unit;
            }

            div {
                text-align: left;
                font-size: $font-size-small * 0.7;
            }
        }
    }
}

.About-container {
    display: flex;
    height: 100%;
}

.AboutUser {
    text-align: left;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: $unit;
        color: $color-text-primary;
    }
}

.Languages {
    width: 50%;
    text-align: right;

    header {
        margin-bottom: $unit;
        label {
            color: $color-text-primary;
        }
    }

    &__list {
        text-align: right;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    // The action button at the bottom of the page
    &__action {
        display: flex;
        justify-content: flex-end;

        button {
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            transition: color 0.3s;
            &:hover {
                color: $color-accent;
            }
        }
    }
}
