@import "./variables";

.Dashboard {
    font-family: $font-family-heading;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;

    li {
        list-style-type: none;
        display: inline-block;
        width: $unit * 8;
        text-align: center;

        :first-child {
            font-weight: 800;
            font-size: $font-size-default;
            margin-bottom: $unit;
            color: $color-primary-900;
        }

        :last-child {
            font-size: $font-size-tiny;
            text-transform: uppercase;
            color: lighten($color-primary-900, 25%);
        }
    }
}
