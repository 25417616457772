/* 
    TODO: these should be phased out in favor of SASS

    These variables are good for a simple setup but are 
    a bit cumbersome for a bigger app where Sass is needed
    for other reasons.

*/
:root {
    --color-primary: #eeeeee;
    --color-primary-900: #33363c;
    --color-primary-950: #272626;
    --color-secondary: #4caf50;
    --color-accent: #26a65b;

    --color-divider: silver;

    --color-background-primary: #fefefe;
    --color-background-secondary: #eee;
}

$color-primary: #eee;
$color-primary-contrast: darken($color-primary, 25%);
$color-primary-900: #33363c;
$color-primary-950: #272626;
$color-primary-800: #d5d6db;
$color-primary-700: #9a9b9c;
$color-divider: silver;

$color-accent: #26a65b;

$color-red: #af4c76;

$color-background-100: #fbf8f5;
$color-background-primary: #fefefe;
$color-background-secondary: #f0f0f0;

$color-text-primary: #5e5f5e;

// -- Nov / Dec 2019 --

$color-background-200: #f8f6f3;
$color-blue-100: #3a3768;
