@import "./variables";

.LanguageCard {
    background-color: $color-primary-800;
    margin-bottom: $unit * 2;
    padding: $unit;
    height: 7 * $unit;
    width: 20 * $unit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        display: flex;
        width: 100%;
        overflow: hidden;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        input,
        select {
            font-size: $font-size-default;
            margin: 0;
            text-transform: capitalize;
            color: $color-primary-900;
            font-weight: 800;
            overflow: hidden;
            background-color: transparent;
            border: none;
            appearance: none;
        }

        &::after {
            content: "";
            position: absolute;
            width: 3 * $unit;
            height: 100%;
            background: url("/arrow_down_24px.svg") no-repeat center center;
            background-size: $unit * 2;
            right: -$unit;
            bottom: 0;
            pointer-events: none;
        }
    }
    &__options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2 * $unit;
        overflow: hidden;

        button {
            background-color: transparent;
            width: 2 * $unit;
            text-align: center;
            color: $color-primary-900;
            max-width: 4 * $unit;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            position: relative;

            span {
                position: relative;
                right: -5px;
                color: $color-primary-700;
            }
        }

        label {
            display: inline-block;
            color: fade-out($color-primary-900, 0.25);
            text-transform: capitalize;
            font-size: $font-size-small;
            height: 2 * $unit;
            overflow: hidden;
            display: flex;
            align-items: center;
        }

        &-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 10 * $unit;
            position: relative;
            height: 2 * $unit;
            overflow: hidden;
            transition: background-color 0.3s;

            &:hover {
                background-color: $color-primary;
            }

            select {
                padding: 0;
                margin: 0;
                font-size: $font-size-small;
                border: 0;
                background-color: transparent;
                border-radius: 0;
                appearance: none;
                color: $color-primary-900;
                display: inline-block;
                width: 10 * $unit;
                height: 100%;
                padding-left: $unit;
            }

            &::after {
                content: "";
                position: absolute;
                width: 2 * $unit;
                height: 100%;
                background: url("/arrow_down_24px.svg") no-repeat center center;
                background-size: $unit * 2;
                right: 0;
                bottom: 0;
                pointer-events: none;
            }
        }
    }
}
