@import "variables";

.DocumentList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $unit;
    grid-auto-rows: minmax(438px, auto);
    width: 100%;
    justify-items: center;
    padding: $unit * 2;
    padding-bottom: $unit * 10;
    margin-bottom: $unit * 10;

    &_container {
        display: inline-block;
        width: 100%;
        height: calc(100vh - #{$unit * 9}); // 96+46 (header + navbar)
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background-color: $color-background-100;
    }
}
