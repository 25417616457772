@import "./variables";

.headerButton {
    font-size: $font-size-small;
    border: 1px solid $color-blue-100;
    color: $color-blue-100;
    background-color: transparent;
    height: $unit * 3;
    padding: 0 $unit;
    display: flex;
    align-items: center;
}

.TitleEdit {
    font-family: $font-family-heading;
    font-size: $font-size-tiny;
    color: $color-primary-900;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-background-200;
    padding: 0;
    margin: 0;

    &--action-review {
        @extend .headerButton;
        background-color: $color-blue-100;
        color: $color-primary;
        text-decoration: none;
    }

    &--title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-section {
            width: 50%;
            height: 100%;
            flex: 1 1 0;
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        label {
            display: block;
            margin-bottom: $unit / 2;
        }
    }

    .me-document-item__image-container {
        max-height: $unit * 6;
        width: $unit * 6;
        background: transparent;

        img {
            border-radius: 0;
        }
    }

    .book {
        &__cover-image {
            background-color: $color-primary;
            width: calc(270px / 2);
            height: calc(414px / 2);
            margin-bottom: $spacing-default;
        }
        &__title {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            transition: font-weight 250ms;

            &:hover {
                color: var(--color-accent);
            }
        }
    }

    &--chapters {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $font-size-small;
        color: $color-primary-900;
        margin-right: $unit;

        a {
            text-decoration: none;
        }

        &-number {
            flex: 2 0 0;
            text-align: center;
            font-size: $font-size-large;
            font-weight: 800;
            height: 3 * $unit;
            padding: 0;
            display: flex;
            align-items: center;

            label {
                font-size: $font-size-tiny;
                font-weight: initial;
                text-transform: uppercase;
                border-bottom: 1px solid $color-primary-900;
                margin-right: $unit;
            }
        }

        &-action {
            @extend .headerButton;
        }
    }
}
